import React from 'react';

interface BioFormProps {
  value: string;
  onChange: (value: string) => void;
  isSubmitting: boolean;
}

export function BioForm({ value, onChange, isSubmitting }: BioFormProps) {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-300 mb-2">
        Bio
      </label>
      <textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full px-4 py-2 bg-gray-800 rounded-lg text-white resize-none focus:ring-2 focus:ring-indigo-500"
        rows={4}
        placeholder="Tell brands about yourself (minimum 50 characters)"
        disabled={isSubmitting}
        minLength={50}
        required
      />
      <p className="text-sm text-gray-400 mt-1">
        {value.length}/500 characters (minimum 50)
      </p>
    </div>
  );
}