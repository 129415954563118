import React, { useState } from 'react';
import { supabase } from '../../lib/supabase';
import { User } from '../../types';
import { ProfilePicture } from './ProfilePicture';
import { SocialLinksForm } from './SocialLinksForm';
import { BioForm } from './BioForm';
import { AlertCircle, CheckCircle } from 'lucide-react';

interface ProfileFormProps {
  user: User;
}

export function ProfileForm({ user }: ProfileFormProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [bio, setBio] = useState(user.bio || '');
  const [socialLinks, setSocialLinks] = useState(user.social_links || {});

  const handleProfilePictureUpdate = async (file: File) => {
    try {
      const fileExt = file.name.split('.').pop();
      const fileName = `${user.id}-${Date.now()}.${fileExt}`;
      const filePath = `${user.id}/${fileName}`;

      // Upload to storage
      const { error: uploadError } = await supabase.storage
        .from('profile-pictures')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('profile-pictures')
        .getPublicUrl(filePath);

      // Update user profile
      const { error: updateError } = await supabase
        .from('users')
        .update({ profile_picture: publicUrl })
        .eq('id', user.id);

      if (updateError) throw updateError;

      setSuccessMessage('Profile picture updated successfully');
      return publicUrl;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update profile picture');
      throw err;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      setError('');
      setSuccessMessage('');

      // Update user profile
      const { error: updateError } = await supabase
        .from('users')
        .update({
          bio,
          social_links: socialLinks
        })
        .eq('id', user.id);

      if (updateError) throw updateError;

      setSuccessMessage('Profile updated successfully');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update profile');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      {error && (
        <div className="flex items-center gap-2 bg-red-500/10 border border-red-500/20 text-red-400 p-4 rounded-lg">
          <AlertCircle className="w-5 h-5" />
          <span>{error}</span>
        </div>
      )}

      {successMessage && (
        <div className="flex items-center gap-2 bg-green-500/10 border border-green-500/20 text-green-400 p-4 rounded-lg">
          <CheckCircle className="w-5 h-5" />
          <span>{successMessage}</span>
        </div>
      )}

      <ProfilePicture 
        user={user} 
        onUpdate={handleProfilePictureUpdate} 
      />

      <BioForm 
        value={bio}
        onChange={setBio}
        isSubmitting={isSubmitting} 
      />

      <SocialLinksForm 
        links={socialLinks}
        onChange={setSocialLinks}
        isSubmitting={isSubmitting} 
      />

      <button
        type="submit"
        disabled={isSubmitting}
        className="button-primary w-full"
      >
        {isSubmitting ? (
          <div className="flex items-center justify-center gap-2">
            <div className="w-5 h-5 border-2 border-white/20 border-t-white rounded-full animate-spin" />
            Saving...
          </div>
        ) : (
          'Save Changes'
        )}
      </button>
    </form>
  );
}