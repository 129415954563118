import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { Header } from './components/layout/Header';
import { Footer } from './components/layout/Footer';
import { LandingPage } from './pages/LandingPage';
import { LoginPage } from './pages/LoginPage';
import { SignupPage } from './pages/SignupPage';
import { DashboardPage } from './pages/DashboardPage';
import { ProfilePage } from './pages/ProfilePage';
import { CreatorProfilePage } from './pages/CreatorProfilePage';
import { LegalPage } from './components/legal/LegalPage';
import { useAuthStore } from './stores/authStore';

function PageTitle() {
  const location = useLocation();
  const { user } = useAuthStore();

  // Define page titles
  const titles: Record<string, string> = {
    '/': 'Sootra Hub',
    '/login': 'Sign In | Sootra Hub',
    '/signup': 'Create Account | Sootra Hub',
    '/dashboard': 'Dashboard',
    '/profile': 'Profile Settings',
    '/legal': 'Legal Information'
  };

  // Get base title from path or use Dashboard as default for authenticated routes
  const baseTitle = titles[location.pathname] || 'Dashboard';
  
  // For authenticated users, don't append "| Sootra Hub" to keep it clean
  const fullTitle = user ? baseTitle : `${baseTitle}${baseTitle === 'Sootra Hub' ? '' : ' | Sootra Hub'}`;

  return (
    <Helmet>
      <title>{fullTitle}</title>
    </Helmet>
  );
}

export default function App() {
  const { user, checkSession, signOut } = useAuthStore();

  useEffect(() => {
    checkSession();
  }, [checkSession]);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <PageTitle />
        <div className="min-h-screen bg-gray-900 flex flex-col">
          <Header user={user} onSignOut={signOut} />
          <main className="flex-1">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={
                user ? <Navigate to="/dashboard" replace /> : <LoginPage />
              } />
              <Route path="/signup" element={
                user ? <Navigate to="/dashboard" replace /> : <SignupPage />
              } />
              <Route path="/dashboard/*" element={
                user ? <DashboardPage /> : <Navigate to="/login" replace />
              } />
              <Route path="/profile" element={
                user ? <ProfilePage /> : <Navigate to="/login" replace />
              } />
              <Route path="/creators/:id" element={
                user ? <CreatorProfilePage /> : <Navigate to="/login" replace />
              } />
              <Route path="/legal/*" element={<LegalPage />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </BrowserRouter>
    </HelmetProvider>
  );
}