import React from 'react';
import { Plus, Minus, Link as LinkIcon } from 'lucide-react';

const PLATFORMS = [
  { value: 'instagram', label: 'Instagram', urlPattern: 'instagram.com' },
  { value: 'twitter', label: 'Twitter', urlPattern: 'twitter.com' },
  { value: 'youtube', label: 'YouTube', urlPattern: 'youtube.com' },
  { value: 'facebook', label: 'Facebook', urlPattern: 'facebook.com' },
  { value: 'linkedin', label: 'LinkedIn', urlPattern: 'linkedin.com' },
  { value: 'tiktok', label: 'TikTok', urlPattern: 'tiktok.com' }
];

interface SocialLinksFormProps {
  links: Record<string, string>;
  onChange: (links: Record<string, string>) => void;
  isSubmitting: boolean;
}

export function SocialLinksForm({ links, onChange, isSubmitting }: SocialLinksFormProps) {
  const handleLinkChange = (platform: string, url: string) => {
    onChange({
      ...links,
      [platform]: url
    });
  };

  const validateSocialLink = (platform: string, url: string) => {
    if (!url) return true; // Empty URLs are allowed
    try {
      const urlObj = new URL(url);
      const platformConfig = PLATFORMS.find(p => p.value === platform);
      if (!platformConfig) return true;
      return urlObj.hostname.includes(platformConfig.urlPattern);
    } catch {
      return false;
    }
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-300 mb-2">
        Social Media Links
      </label>
      {PLATFORMS.map((platform) => (
        <div key={platform.value} className="flex gap-2 mb-2">
          <div className="w-40">
            <label className="text-sm text-gray-400">{platform.label}</label>
          </div>
          <div className="flex-1 relative">
            <LinkIcon className="w-4 h-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="url"
              value={links[platform.value] || ''}
              onChange={(e) => handleLinkChange(platform.value, e.target.value)}
              className={`w-full pl-10 pr-4 py-2 rounded-lg bg-gray-800 text-white focus:ring-2 focus:ring-indigo-500 ${
                links[platform.value] && !validateSocialLink(platform.value, links[platform.value])
                  ? 'border-red-500'
                  : ''
              }`}
              placeholder={`Enter your ${platform.label} profile URL`}
              disabled={isSubmitting}
            />
            {links[platform.value] && !validateSocialLink(platform.value, links[platform.value]) && (
              <p className="text-red-400 text-sm mt-1">
                Please enter a valid {platform.label} URL
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}